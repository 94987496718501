<template>
  <div>
    <b-card body-bg-variant="light"
            class="ma-5"
            header="Affiliations"
            header-bg-variant="dark"
            header-text-variant="light">
      <b-button :pressed.sync="showFilter"
                class="mb-2">
        FILTER
      </b-button>
      <div class="right div--button">
        Rows: {{ users.length }}
      </div>
      <b-table :current-page="currentPage"
               :fields="fields"
               :items="users"
               :per-page="perPage"
               class="table-responsive"
               head-variant="light"
               hover
               responsive
               striped>

        <template v-slot:top-row="{fields}">
          <proposal-filter :id="id"
                           v-model="users"
                           :fields="fields"
                           :not_include_key="['actions', 'ordinalNumber']">
          </proposal-filter>
        </template>

        <template v-slot:cell(ordinalNumber)="row">
          <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
        </template>

        <template v-slot:cell(actions)="row">
          <b-btn v-b-modal.affiliation_modal
                 variant="outline-info"
                 @click="open_affiliation(row)">MANAGE
          </b-btn>
        </template>

      </b-table>
      <b-pagination v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="users.length"
                    size="md">
      </b-pagination>
    </b-card>
    <b-modal id="affiliation_modal"
             lazy
             @ok="save_data">
      <affiliation-form v-model="affiliation"
                        :validator="validator"
                        is_operator>
        <template v-slot:header>
          <b-form-select v-if="affiliation.states"
                         v-model="affiliation.states.name"
                         :options="affiliation_states"/>
        </template>
      </affiliation-form>
    </b-modal>
  </div>
</template>

<script>
  import { affiliation_validator } from '@/assets/validators/affiliation_validator';
  import AffiliationForm from '@/components/AffiliationForm';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    components: {
      BaseColumnOrdinalNumber,
      AffiliationForm,
      proposalFilter,
    },
    data() {
      return {

        // necessary for proposal and calls filter feature
        id: 1,

        // use only for visual effect and trigger event for filter component
        showFilter: false,

        validator: affiliation_validator,
        currentPage: 1,
        perPage: 10,
        users: [],
        user_id: undefined,
        aff_index: 0,
        affiliation_states: [],
        affiliation: {},
        temp_affiliation: undefined,
        fields: [
          ordinalNumber,
          {
            key: 'title',
            thStyle: { width: '5%' },
          },
          'first_name',
          'last_name',
          {
            key: 'email',
            label: 'Main email',
          },
          {
            key: 'affiliations.email',
            label: 'Affiliation email',
          },
          {
            key: 'affiliations.organization.name',
            label: 'Organization name',
            formatter: (value) => (value || 'N/A'),
          },
          {
            key: 'affiliations.organization.city',
            label: 'Organization city',
            formatter: (value) => (value || 'N/A'),
          },
          {
            key: 'affiliations.organization.country',
            label: 'Organization country',
            formatter: (value) => (value || 'N/A'),
          },
          {
            key: 'affiliations.states.name',
            label: 'State',
            formatter: (value) => (value ? this.$options.filters.pretty(value) : 'N/A'),
          },
          {
            key: 'affiliations.states.date',
            label: 'Last modified',
            formatter: (value) => (value || 'N/A'),
          },
          { key: 'actions' },
        ],
      };
    },
    methods: {
      load_data() {
        this.axios.all([
          this.axios.get('/users/affiliations'),
          this.axios.get('affiliations/states'),
        ])
          .then(this.axios.spread((users, states) => {
            this.users = users.data;
            this.$emit('setup_proposal', this.users);
            this.affiliation_states = states.data;
          }));
      },
      open_affiliation(row) {
        this.user_id = row.item._id.$oid;
        this.aff_index = row.item.arrayIndex;
        this.temp_affiliation = row;
        this.affiliation = JSON.parse(JSON.stringify(row.item.affiliations));
      },
      save_data() {
        // move id of selected organization to field organization_id
        this.axios.patch(`/affiliations/${this.user_id}/${this.aff_index}`, this.affiliation)
          .then(() => {
            this.$set(this.temp_affiliation.item, 'affiliations', this.affiliation);
            this.$notify({ type: 'success', title: 'Affiliation saved successfully!' });
          })
          .catch(() => {
            this.$notify({ type: 'error', title: 'Affiliation cannot be saved!' });
            this.load_data();
          });
      },
    },
    mounted() {
      this.load_data();
    },
    watch: {
      showFilter() {
        this.$emit('toggle_show');
      },
    },
  };
</script>

<style scoped>
</style>
